import React, { useState } from 'react';
import './App.css';
import MaterialTable from 'material-table'
import { MuiThemeProvider, createMuiTheme, FormControlLabel, Switch } from '@material-ui/core'
// import CssBaseline from '@material-ui/core/CssBaseline';


const empList = [
  { id: 1, name: "LexName1", email: 'testuser1@gmail.com', status: 0, role: 1 },
  { id: 2, name: "LexName2", email: 'testuser2@gmail.com', status: 1, role: 2 },
  { id: 3, name: "LexName3", email: 'testuser3@gmail.com', status: 1, role: 3 },
  { id: 4, name: "LexName4", email: 'testuser4@gmail.com', status: 0, role: 4 },
]

function App() {

  const [preferDarkMode, setPreferDarkMode] = useState(() => {
    const mode = localStorage.getItem('_tableDarkMode')
    return mode === "true" || false
  })
  
  const columns = [
    { title: "ID", field: "id" },
    { title: "Name", field: "name" },
    { title: "Email", field: "email" }, 
    { title: "Status", field: 'status', },
    { title: "Role", field: "role", }
  ] 
  
  const theme = createMuiTheme({
    palette: {
      type: preferDarkMode ? 'dark' : 'light'
    }
  })

  const handleDarkModeChange = () => {
    setPreferDarkMode(!preferDarkMode)
    localStorage.setItem('_tableDarkMode', !preferDarkMode)
  }

  return (
    <div className="App">
      {/* <MuiThemeProvider theme={theme}> */}
        {/* <CssBaseline /> */}
      {/* <CssBaseline> */}
      <h1 align="center">React-App</h1>
      <h4 align='center'>Material Table</h4>
      {/* </CssBaseline> */}

      <FormControlLabel
        value="top"
        control={<Switch color="primary" checked={preferDarkMode} />}
        onChange={handleDarkModeChange}
        label="Dark Mode"
        labelPlacement="top"
        />
      <MuiThemeProvider theme={theme}>
        {/* <CssBaseline /> */}
        <MaterialTable
          title="Employee Data"
          data={empList}
          columns={columns}
        />
      </MuiThemeProvider>






      
    </div>
  );
}

export default App;
